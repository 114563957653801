module.exports = {
  locales: ['en', 'nl', 'de', 'es', 'fr'],
  defaultLocale: 'en',

  sentryOptions: {
    widenClientFileUpload: true,
    hideSourceMaps: false,
  },
  sentryWebpackPluginOptions: {
    silent: true,
  },

  sentryIgnoreErrors: [
    'NEXT_NOT_FOUND',
    'NotFoundError',
    // Random plugins/extensions
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'TypeError: Load failed',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'AbortError',
    'NS_ERROR_NOT_INITIALIZED',
  ],
}
