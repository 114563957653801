var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9a63c754c9396623c0270db875c3c1c085cc0157"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'
import { Replay } from '@sentry/replay'

import { sentryIgnoreErrors } from './config'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  sampleRate: 0.2,
  integrations: [new Replay()],
  environment: process.env.PRODUCTION.toLowerCase() === 'true' ? 'production' : 'development',
  release: process.env.SENTRY_RELEASE,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  initialScope: {
    tags: { environment: 'client' },
  },
  ignoreErrors: sentryIgnoreErrors,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
